<template>
  <div class="breadcrumbs">
    <template v-for="(route, index) in routes">
      <router-link
        v-if="route.to"
        :key="route.name"
        :to="route.to"
        class="breadcrumbs__item breadcrumbs__item--link"
      >
          {{ (index !== 0 ? ' / ' : '') + route.name }}
      </router-link>

      <span v-else class="breadcrumbs__item">
        {{ (index !== 0 ? ' / ' : '') + route.name }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: "BreadCrumbs",
  data: () => ({}),
  props: {
    routes: {
      type: Array,
      default: () => [],
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/style/fonts.css';
@import '../assets/style/vars';

.breadcrumbs {
  display: block;
  height: min-content;
  white-space: nowrap;

  &__item {
    color: #3C7E79;
    font-size: 13px;
    font-family: $R;
    text-decoration: none;
    transition: color 0.3s;

    &--link {
      &:hover {
        color: #00A03B;
        text-decoration: none;
      }
    }
  }
}
</style>
