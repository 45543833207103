<template>
  <div class="production-swiper-wrapper">
    <swiper ref="productionSwiperRef" :options="swiperOptions" class="production-swiper">
      <swiper-slide v-if="production.title_photo !== null && production.title_photo !== ''"
        class="production-swiper__slide">
        <img :src="`${$root.globalUrl}/uploads/drugs/${production.title_photo}`">
      </swiper-slide>

      <swiper-slide v-if="production.back_photo !== null && production.back_photo !== ''"
        class="production-swiper__slide">
        <img :src="`${$root.globalUrl}/uploads/drugs/${production.back_photo}`">
      </swiper-slide>

      <swiper-slide v-if="key !== null && key !== ''" v-for="(key, value) in production.other_photos" :key="value"
        class="production-swiper__slide">
        <img :src="`${$root.globalUrl}/uploads/box_pictures/${key}`">
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>

      <div class="swiper-button swiper-button-prev hover_type7" slot="button-prev">
        <svg style="margin-right: 4px;" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 17L2 9.12513L10 1" stroke="#00A03B" stroke-width="2" stroke-linecap="round" />
        </svg>
      </div>

      <div class="swiper-button swiper-button-next hover_type7" slot="button-next">
        <svg style="margin-left: 4px;" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L9 8.87488L0.999998 17" stroke="#00A03B" stroke-width="2" stroke-linecap="round" />
        </svg>
      </div>
    </swiper>
  </div>
</template>

<script>
  export default {
    name: 'ProductionSwiper',

    components: {},

    props: {
      production: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        swiperOptions: {
          loop: true,
          pagination: {
            el: '.swiper-pagination',
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        },
      };
    },
  };
</script>

<style scoped lang="scss">
  @import "../../assets/style/fonts.css";
  @import "../../assets/style/vars";

  .production-swiper-wrapper {
    max-width: 690px;
    min-width: 300px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px 0;

    background: #FFFFFF;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    height: max-content;

    @media screen and (max-width: 768px) {
      max-width: 768px;
      min-width: 320px;

      padding: 0;
      box-shadow: none;
    }
  }

  .production-swiper {
    min-height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;

    @media screen and (max-width: 768px) {
      min-height: max-content;
    }

    &__slide {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 80%;
        height: auto;
        object-fit: contain;
        display: block;
      }
    }

    ::v-deep .swiper-wrapper {
      display: flex;
      align-items: center;
    }
  }

  .swiper-pagination {
    bottom: 40px;
    left: 25px;

    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      justify-content: center;
      bottom: 0;
      left: 0;
    }

    ::v-deep .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      transform-origin: center;
      background-color: $green;
      opacity: 1;
      z-index: 1;

      &.swiper-pagination-bullet-active {
        width: 10px;
        height: 10px;
      }
    }
  }

  .swiper-button {
    top: auto;
    bottom: 30px;
    left: auto;

    height: 40px;
    width: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px solid $green;
    border-radius: 50%;

    &:after {
      display: none;
    }

    svg {
      max-width: 11px;
      width: 100%;
      height: auto;
      display: block;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .swiper-button-prev {
    right: 80px;
  }

  .swiper-button-next {
    right: 30px;
  }
</style>